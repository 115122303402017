<template>
  <div>
    <CRow v-if="platformPermissionsLoaded && checkPermission('connect.moments.overview')" class="events">
      <CCol cols="12" lg="12" md="12">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
                <span>{{$t('common.Auto_moments')}}</span>
              </CCol>
              <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
                <div class="mt-1 mt-xl-0 d-flex align-items-start justify-content-end">
                  <b-switch v-if="moments.length > 0" class="mb-0 mr-2" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
                  <CButton v-if="modules.auto_moments == 1" class="ml-0 mr-2 orange" color="primary" @click="openSidebarRight('custom_product_auto_moment', { product_tag:  'connect' })">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{$t('communications.Add_custom_auto_moment')}}</span>
                  </CButton>                  
                  <CButton v-if="modules.auto_moments == 1" class="m-0" color="primary" @click="openSidebarRight('add_product_auto_moments', { product_tag:  'connect', show_instructions: false })">                  
                    <span><i class="fas fa-plus mr-1"/>{{$t('communications.Add_auto_moments')}}</span>
                  </CButton>                  
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class=" p-0" v-bind:class="{'auto_moment_catalog' : !listViewEnabled, 'auto_moment_list' : listViewEnabled}">
            <div v-if="momentsLoading === true">
              <CRow >
                <CCol cols="12" lg="12">
                  <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                </CCol>
              </CRow>          
            </div>
            <div v-else>
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <b-table class="data_table includes_dropdown" :data="moments" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                      <template slot-scope="props">
                        <b-table-column field="image" width="5%">
                          <div class="moment_icon">
                            <img v-if="props.row.event_type_image_id && !props.row.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/global/' + props.row.event_type_image_id + '/' + clientToken"/>
                            <img v-else-if="props.row.event_type_image_id && props.row.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-custom/' + props.row.tag + '/' + props.row.event_type_image_id + '/' + clientToken"/>
                            <img v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/common/calendar.png/' + clientToken"/>
                          </div>
                        </b-table-column>
                        <b-table-column field="description" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true">  
                          <b>{{props.row.description}}</b>
                        </b-table-column>
                        <b-table-column field="send_on_date" :label="$t('communications.Send_on_date')" :searchable="searchEnabled" :sortable="true">
                          <div class="d-flex align-items-center date">
                            <i class="fa-regular fa-calendar mr-1_5"></i>
                            <span v-if="props.row.tag === 'breaking_news'">{{$t('communications.With_every_important_update')}}</span>
                            <span v-else-if="props.row.tag === 'first_day'">{{$t('communications.For_every_first_working_day')}}</span>
                            <span v-else-if="props.row.tag === 'introduction'">{{$t('communications.A_week_before_employee_starts')}}</span>                          
                            <span v-else>-</span>
                          </div>
                        </b-table-column>
                      <b-table-column field="actions">
                        <div class="d-flex justify-content-lg-end">                          
                          <CDropdown color="primary"
                                     toggler-text=""
                                     :caret="false"
                                     class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem v-if="props.row.preview_available">
                              <div class="d-flex align-items-center" @click="showMomentEmailPreview(props.row.product_tag, props.row.tag)">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-search"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('communications.Preview_moment_email')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="showMomentEditPage(props.row.platform_event_type_id_external);">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-pen"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('communications.Edit_moment')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </b-table-column>                        
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <span>{{$t('communications.No_moments_available')}}</span>
                        </div>
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>                
              </div>
              <div v-else>
                <div v-if="moments.length > 0">
                  <CRow class="m-0 moments">
                    <CCol v-for="moment in currentPageMoments[currentMomentPage - 1]" :key="moment.platform_event_type_id_external" cols="3" xl="3" lg="4" md="6" class="p-0">
                      <AutoMoment :momentData="moment" :showEditButton="checkPermission('connect.moments.overview')" parent="overview"/>
                    </CCol>
                  </CRow>
                  <hr v-if="moments.length > momentsPerPage" class="m-0">
                  <CRow v-if="moments.length > momentsPerPage" class="m-0">
                    <CCol cols="12" md="12">
                      <v-pagination class="justify-content-end" v-model="currentMomentPage" :length="momentPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow class="m-0">
                    <CCol cols="12" lg="12">
                      <span>{{$t('communications.No_moments_available')}}</span>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>

    <b-modal class="email_preview position-absolute" :can-cancel="['x']" :active.sync="emailPreviewModal" :width="600" scroll="keep">
      <CCard class="mb-0">
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-html="emailPreviewModalData.email_content"></div>           
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="emailPreviewModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import AutoMoment from '@/components/core/AutoMoment.vue';
import NewMomentPopover from '@/components/core/NewMomentPopover.vue';

export default {
  name: 'Configure',
  components: {
    loadingSpinner,
    noPermission,
    AutoMoment,
    NewMomentPopover
  },
  data() {
    return {
      modules: {
        auto_moments: 0
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      moments: [],
      momentsLoading: false,
      momentsLoaded: false,
      emailPreviewModal: false,
      emailPreviewModalData: {},
      listViewEnabled: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      searchEnabled: true,
      currentPage: 1,
      perPage: 10,      
      currentMomentPage: 1,
      currentPageMoments: {},
      momentsPerPage: 12,
      momentPages: 0,      
    }
  },
  methods: {
    getAutoMoments() {
      // Start the loader
      if(this.momentsLoaded === false) this.momentsLoading = true;
      // Get the moments
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/events/connect/moments/overview')
      .then(res => {
        this.moments = res.data.data;
        // Set the pagination to the first page
        this.currentMomentPage = 1;        
        // Reset the momentPages value
        this.momentPages = 0;        
        // Loop through the moments to set the pagination
        for (let i = 0; i < this.moments.length; i = i + this.momentsPerPage) {
          this.currentPageMoments[this.momentPages] = this.moments.slice(i, i + this.momentsPerPage);
          this.momentPages++;
        }
        // Check if the pagination for the table should be enables
        this.moments.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.momentsLoading = false;
        // Update the momentsLoaded value
        this.momentsLoaded = true;     
      })
      .catch(err => {
        console.error(err); 
      }); 
    },  
    showMomentEmailPreview(productTag, eventTypeTag) {      
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/' + productTag + '/' + eventTypeTag + '/preview')
      .then(res => {
        // Set the emailPreviewModalData
        this.emailPreviewModalData = res.data.data;
        // Open the modal
        this.emailPreviewModal = true;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    showMomentEditPage(id) {
      this.$router.push({path: `/connect/auto-moment/${id.toString()}`});
    },       
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.checkModules();
    this.getPlatformPermissions();
    this.getAutoMoments();    

    this.$bus.$on('custom_connect_moment_added', () => {      
      this.getAutoMoments();
    });

    this.$bus.$on('connect_moments_updated', (e) => {
      this.getAutoMoments();
    }); 

    this.$bus.$on('email_template_added', (e) => {
      this.getAutoMoments();
    });

    this.$bus.$on('show_moment_email_preview', (e) => {
      this.showMomentEmailPreview(e.product_tag, e.tag);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('custom_connect_moment_added');
    this.$bus.$off('connect_moments_updated');
    this.$bus.$off('email_template_added');
    this.$bus.$off('show_moment_email_preview');
  }
}
</script>
